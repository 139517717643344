<template>
 <div>
  <header class="header header-fixed">
    <div></div>
    <el-popover width="250" v-model="visiblepop" class="user-info" :visible-arrow="false" popper-class="popper-menu">
      <span class="user-name" slot="reference">
        <span>{{user.userName}}</span>
        <span class="user-type">{{user.currentRoleName}}</span>
        <i class="el-icon-arrow-down" ></i>
      </span>
      <div class="user-menu">
        <div class="user-menu-item" @click="information">
          <i class="iconfont " style="font-size;18px;"> &#xeb61;</i>
          <span>个人信息</span>
          <i class="el-icon-arrow-right" :size="44" />
        </div>
        <div class="user-menu-item" @click="changePassword">
          <i class="iconfont ">&#xe605;</i>
          <span>修改密码</span>
          <i class="el-icon-arrow-right" />
        </div>
        <div class="user-menu-line"></div>
        <div class="user-menu-item" @click="signoutLogin" >
          <i class="iconfont ">&#xe602;</i>
          <span>退出</span>
        </div>
      </div>
    </el-popover>
  </header>

  <!-- 个人信息 -->
    <Dialog
    @close="closeDialog"
    :title="'个人信息'"
    :width="'576px'"
    :visible="visible">
      <ul class="information-wrap" >
        <li v-for="(item,index) in informationList" :key="index">
          <span>{{item.label}}</span>
          <span>{{item.text}}</span>
        </li>
      </ul>
    </Dialog>

    <!-- 修改密码 -->
    <ChangePassword
    :visiblepass.sync="visiblepass"
    :first-login="firstLogin">
    </ChangePassword>
 </div>

</template>
<script>
import Dialog from '@/components/Dialog'
import ChangePassword from '../../components/change-password'
import {
  clearLocalStorage
} from '@/utils/storage.js'
import {
  logout,
  // firstPassword
} from '@/api/modules/login'
import { mapGetters } from 'vuex'
export default {
  name: 'Header',
  components: {
    Dialog,
    ChangePassword
  },
  data() {
    return {
      visible: false,
      visiblepop: false,
      visiblepass: false,
      user: null,
      informationList: [
        { label: '姓名：', text: '', sign: 'userName' },
        { label: '手机号：', text: '', sign: 'phone' },
        { label: '角色：', text: '', sign: 'currentRoleName' },
        { label: '邮箱：', text: '', sign: 'email' }
      ],
    }
  },
  created() {
    this.user = this.$store.getters.userInformation
    console.log('userInformation:', this.user)
    const { userName, phone, currentRoleName, email } = this.$store.getters.userInformation
    const newboj = { userName, phone, currentRoleName, email }
    this.informationList.forEach((item) => {
      for (const key in newboj) {
        if (item.sign === key) {
          item.text = newboj[key]
        }
      }
    })
  },
  computed: {
    ...mapGetters(['authToken']),
    firstLogin() {
      return this.user.firstLogin === 1
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.firstLogin) {
        this.visiblepass = true
      }
    })
  },
  methods: {
    logout() {
      this.$router.push('/login')
    },
    information() {
      this.visiblepop = false
      this.visible = true
    },
    changePassword() {
      this.visiblepop = false
      this.visiblepass = true
    },
    closeDialog() {
      this.visible = false
    },
    // 退出登录
    signoutLogin() {
      logout().then(res => {
        if (res.code === '000000000') {
          this.visiblepop = false
          clearLocalStorage()
          this.$router.replace({ name: 'Login' })
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/var.scss';
.header {
  // z-index: 100;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: $header_height;
  line-height: $header_height;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  justify-content: space-between;
  background-color: $white;
}
.header-fixed {
  position: relative;
  width: auto;
  top: 0;
  right: 0;
  left: 0;
  height: 56px;
  line-height: 56px;
}
.information-wrap{
  margin-bottom: 22px;
  font-size: 14px;
  color: #323233 ;
  li{
    margin: 20px 0 0 36px;
  }
  li > span:first-child{
    display: inline-block;
    width: 98px;
  }
  li > span:last-child{
    font-size: 14px;
    font-weight: 500;
    color: #323233;
  }
  li:first-child{
    margin-top:0;
  }
}
.user-info {
  .user-name {
    padding-right: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #323233;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
  }
  .user-type{
    // display: inline-block;
    font-size: 12px;
    padding: 4px 8px;
    line-height: 20px;
    background: #EBEDF0;
    color: #666666;
    border-radius: 10px;
    text-align: center;
    margin: 0 10px;
    font-weight: 400;
  }
}
.popper-menu {
  margin: 28px 10px 0 0 !important;
  padding: 4px;
  width: 250px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(1, 23, 44, 0.08), 0px 6px 10px 0px rgba(1, 23, 44, 0.08);
  border-radius: 8px;
  box-sizing: border-box;
  .user-menu {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
    .user-menu-item {
      user-select: none;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      padding: 10px 10px;
      color: #666666;
      border-radius: 8px;
      letter-spacing: 0.07em;
      &:hover {
        background: #ebedf1;
        transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
      }
      i:nth-of-type(1) {
        margin-right: 15px;
      }
      span {
        flex: 1;
      }
    }
    .user-menu-line {
      height: 1px;
      background: #ebedf0;
      margin: 4px 16px;
    }
  }
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>