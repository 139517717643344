<template>
  <div class="app-wrap" :style="`--scrollbar-width: -${scrollBarWidth}px`">
    <!-- 菜单栏 -->
    <Side></Side>
    <el-scrollbar class="main-content">
      <!-- 头部 -->
      <Header></Header>
      <!-- 面包屑 -->
      <el-breadcrumb class="main-breadcrumb" separator-class="el-icon-arrow-right">
        <transition-group name="slide-fade" mode="out-in">
          <el-breadcrumb-item v-for="menu of history" :key="menu.path" :to="{ path: menu.path }">
            {{ menu.meta.title }}
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
      <!-- 主体内容 -->
      <transition name="zoom-view" mode="out-in">
        <el-scrollbar :vertical="true" class="main-box" ref="mainBoxRef" :key="$route.fullPath">
          <!-- 模拟顶层 border -->
          <div class="top-sticky" :style="stickyStyle" />
          <!-- 内容 -->
          <router-view />
        </el-scrollbar>
      </transition>
    </el-scrollbar>
  </div>
</template>

<script>
import Side from './components/Side'
import Header from './components/Header'
import { computed, reactive, ref, watch } from '@vue/composition-api'
import { useScroll, useElementSize, throttledWatch } from '@vueuse/core'
import getScrollbarWidth from 'element-ui/src/utils/scrollbar-width'

export default {
  name: 'Layout',
  setup() {
    const mainBoxRef = ref(null)
    const state = reactive({
      y: 0
    })
    // 调整 stickTopOpacty
    watch(mainBoxRef, () => {
      if (!mainBoxRef.value?.$refs?.wrap) return
      const { y } = useScroll(mainBoxRef.value.$refs.wrap, {
        throttle: 16
      })
      state.y = y
    })
    const stickyStyle = computed(() => {
      const stickTopOpacty = state.y >= 36 ? 1 : state.y / 36
      return `background: linear-gradient(180deg, rgba(255, 255, 255, ${stickTopOpacty}) 0%, rgba(255, 255, 255, ${stickTopOpacty}) 25%, transparent 100%)`
    })
    // 调整滚动条
    const { width, height } = useElementSize(mainBoxRef)
    throttledWatch(
      [width, height],
      () => {
        mainBoxRef.value && mainBoxRef.value.update()
      },
      { throttle: 100 }
    )

    return {
      mainBoxRef,
      stickyStyle
    }
  },
  components: {
    Side,
    Header,
  },
  data() {
    return {
      history: this.$route.matched,
      scrollBarWidth: getScrollbarWidth()
    }
  },
  mounted() {
    this.recordHistory()
  },
  methods: {
    recordHistory() {
      this.$router.beforeEach((to, from, next) => {
        // 最终匹配路由
        const finalMatched = to.matched[to.matched.length - 1]
        // 初始匹配路由相同，且最终匹配路由不为菜单项
        if (from.matched[0].path === to.matched[0].path && !finalMatched.meta.isMenu) {
          this.history = [...from.matched.filter((item) => item.meta.isMenu), finalMatched]
        } else {
          this.history = to.matched
        }
        next()
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/style/var.scss';
.app-wrap {
  min-height: 100vh;
  position: relative;
}
.main-content {
  min-height: 100vh;
  margin-left: $side_bar_width;
  background-color: #ebedf1;
  // padding-bottom: 10px;
  box-sizing: border-box;
  overflow: hidden;
  & > .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .main-breadcrumb {
    margin: 10px 24px;
    line-height: 22px;
    & .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      font-weight: 400;
      color: #323233;
    }
    & .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
      color: #323233;
    }
    & .el-breadcrumb__inner.is-link {
      font-weight: 400;
      color: #a2a3a5;
    }
    & .el-breadcrumb__inner.is-link:hover {
      color: #85cf26;
    }
    & > span > span {
      will-change: translateX;
    }
  }
  .main-box {
    margin: 0 20px 20px 20px;
    background-color: $white;
    box-sizing: border-box;
    height: calc(100vh - 120px);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(1, 23, 44, 0.08);
    overflow: hidden;
    position: relative;
    & > .el-scrollbar__wrap {
      height: calc(100vh - var(--scrollbar-width) - 120px);
    }
    & > .el-scrollbar__bar {
      z-index: 5;
    }
    .top-sticky {
      width: 100%;
      height: 36px;
      position: absolute;
      top: 0;
      z-index: 4;
      pointer-events: none;
      will-change: background;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    transition-delay: 0.45s;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .zoom-view-enter-active,
  .zoom-view-leave-active {
    opacity: 1;
    transform: scale(1);
    transition: transform 0.15s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center center;
  }
  .zoom-view-enter {
    opacity: 0;
    transform: scale(0.985);
  }
  .zoom-view-leave-active {
    opacity: 0;
    transform: scale(0.985);
  }
}

</style>
