<template>
  <div class="side-bar">
    <div class="company-logo">
      <img src="@/assets/images/fc-logo.png" alt="" class="logo" />
      <div class="company-name" >lOT平台</div>
    </div>
    <el-menu
      router
      style="border-right: none;"
      class="side-bar-menu"
      background-color="#28282D"
      text-color="rgba(255,255,255,0.8)"
      active-text-color="#85CF26"
      :default-active="onRoutes"
      >
      <template v-for="item in muneList">
        <!-- 主菜单 -->
        <template v-if="item.children && item.children.length >= 1">
          <el-submenu :index="item.resUrl" :key="item.resUrl">
            <template slot="title">
            <i :class="['iconfont',item.icon]"></i>
              <span style="margin-left: 8px;" slot="title" >{{ item.resName }}</span>
            </template>
            <!-- 子菜单 -->
            <template v-for="(subItem, i) in item.children">
              <el-menu-item :key="i" :index="subItem.resUrl" class="menu-item">
                <span slot="title">{{ subItem.resName }}</span>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.resUrl" :key="item.resUrl" >
            <i :class="['iconfont',item.icon]"></i>
            <span slot="title">{{ item.resName }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { getRolus } from '@/api/modules/layout'
// import {
//   clearLocalStorage
// } from '@/utils/storage.js'
export default {
  name: 'Side',
  data() {
    return {
      muneList: []
    }
  },
  computed: {
    onRoutes() {
      // console.log('this.$route.path', this.$route.path)
      return this.$route.path
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    getlist() {
      getRolus().then(res => {
        // console.log('res: ', res)
        if (res.code === '000000000') {
          this.muneList = res.data
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/var.scss';
.side-bar-menu:not(.el-menu--collapse) {
  width: $side_bar_width;
  min-height: 400px;
  &::v-deep .el-menu-item {
    padding-left: 49px !important;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      left: 30px;
      top: 0;
      bottom: 0;
      width: 1px;
      background: rgba(255, 255, 255, 0.1);
    }
    &:hover{
      color: #85CF26 !important;
    }
  }
  &::v-deep .el-menu-item.is-active {
    &::after{
      content: '';
      position: absolute;
      left: 30px;
      top: calc(50% - 8px);
      height: 16px;
      width: 1px;
      background: #85CF26;
    }
  }
  &::v-deep .el-submenu__icon-arrow{
    font-weight: bold;
  }
}
.side-bar {
  position: fixed;
  overflow: auto;
  z-index: 2;
  background: $side-bar-bg;
  left: 0;
  top: 0;
  bottom: 0;
  .company-logo {
    position: sticky;
    left: 0;
    width: 100%;
    height: 92px;
    top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 1;
    background: $side-bar-bg;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .logo {
      height: 33px;
    }
    .company-name {
      color: $white;
      font-size: 18px;
      font-weight: 500;
      margin-left:12px ;
    }
    .company-name::before{
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      background: #FFFFFF;
      border-radius:50%;
      margin-right: 7px;
      position: relative;
      top: -5px;
    }
  }
}
.menu-item {
  display: flex;
  align-items: center;
  .spot {
    display: inline-block;
    width: 2px;
    height: 16px;
    background: #85CF26;
    // border: 1px solid rgba(255, 255, 255, 0.32);
    // border-radius: 6px;
    // margin-left: -10px;
    // margin-right: 5px;
  }
}
</style>